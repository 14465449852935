// Generated by Framer (78a4586)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, Link, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Hero } from "https://framerusercontent.com/modules/hKjtTuWGYB451ckw6eTN/bbq95ZQZ142lE2fXscHd/Hero.js";
import * as sharedStyle from "../css/rD_cisoL3";
const HeroFonts = getFonts(Hero);

const enabledGestures = {sRoEZwoUQ: {hover: true}};

const cycleOrder = ["sRoEZwoUQ", "Ug3_xLLh9"];

const serializationHash = "framer-KBh13"

const variantClassNames = {sRoEZwoUQ: "framer-v-hcx2dw", Ug3_xLLh9: "framer-v-a4s6m1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Closed: "sRoEZwoUQ", Open: "Ug3_xLLh9"}

const getProps = ({click3, height, hover2, hover3, id, link, subnav, title, width, ...props}) => { return {...props, DKgLhN2qI: hover3 ?? props.DKgLhN2qI, E3x7ifE4G: click3 ?? props.E3x7ifE4G, HGoxJpwjL: title ?? props.HGoxJpwjL ?? "Evenementen", KAQrmVyfg: hover2 ?? props.KAQrmVyfg, PRG4daZ8L: subnav ?? props.PRG4daZ8L, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "sRoEZwoUQ", xA9wAxaZt: link ?? props.xA9wAxaZt} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;subnav?: boolean;title?: string;link?: string;click3?: any;hover2?: any;hover3?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, PRG4daZ8L, HGoxJpwjL, xA9wAxaZt, E3x7ifE4G, KAQrmVyfg, DKgLhN2qI, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "sRoEZwoUQ", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapymqroa = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (E3x7ifE4G) {const res = await E3x7ifE4G(...args);
if (res === false) return false;}
})

const onMouseEnter1k85xgt = activeVariantCallback(async (...args) => {
if (KAQrmVyfg) {const res = await KAQrmVyfg(...args);
if (res === false) return false;}
if (DKgLhN2qI) {const res = await DKgLhN2qI(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-hcx2dw", className, classNames)} data-framer-name={"Closed"} data-highlight layoutDependency={layoutDependency} layoutId={"sRoEZwoUQ"} onMouseEnter={onMouseEnter1k85xgt} onTap={onTapymqroa} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"sRoEZwoUQ-hover": {"data-framer-name": undefined}, Ug3_xLLh9: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><Link href={xA9wAxaZt}><motion.a className={"framer-1k5rrni framer-hny0c3"} layoutDependency={layoutDependency} layoutId={"s6QzqT7le"}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-r4e2m1"} data-styles-preset={"rD_cisoL3"}>Evenementen</motion.p></React.Fragment>} className={"framer-xdru1t"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"gSXoLp3Ux"} style={{opacity: 1}} text={HGoxJpwjL} variants={{"sRoEZwoUQ-hover": {opacity: 0.8}}} verticalAlignment={"center"} withExternalLayout/>{PRG4daZ8L && (<ComponentViewportProvider ><motion.div className={"framer-zsvhyb-container"} layoutDependency={layoutDependency} layoutId={"CwVX3S8ba-container"} style={{rotate: 0}} variants={{"sRoEZwoUQ-hover": {rotate: -180}, Ug3_xLLh9: {rotate: -180}}}><Hero color={"rgb(196, 196, 196)"} height={"100%"} iconSearch={"Home"} iconSelection={"ChevronDown"} id={"CwVX3S8ba"} layoutId={"CwVX3S8ba"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider>)}</motion.a></Link></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-KBh13.framer-hny0c3, .framer-KBh13 .framer-hny0c3 { display: block; }", ".framer-KBh13.framer-hcx2dw { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-KBh13 .framer-1k5rrni { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-KBh13 .framer-xdru1t { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-KBh13 .framer-zsvhyb-container { flex: none; height: 16px; position: relative; width: 17px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-KBh13.framer-hcx2dw, .framer-KBh13 .framer-1k5rrni { gap: 0px; } .framer-KBh13.framer-hcx2dw > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-KBh13.framer-hcx2dw > :first-child, .framer-KBh13 .framer-1k5rrni > :first-child { margin-left: 0px; } .framer-KBh13.framer-hcx2dw > :last-child, .framer-KBh13 .framer-1k5rrni > :last-child { margin-right: 0px; } .framer-KBh13 .framer-1k5rrni > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 27
 * @framerIntrinsicWidth 99.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"Ug3_xLLh9":{"layout":["auto","auto"]},"ov8f7JSJN":{"layout":["auto","auto"]}}}
 * @framerVariables {"PRG4daZ8L":"subnav","HGoxJpwjL":"title","xA9wAxaZt":"link","E3x7ifE4G":"click3","KAQrmVyfg":"hover2","DKgLhN2qI":"hover3"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framere08FtnXfP: React.ComponentType<Props> = withCSS(Component, css, "framer-KBh13") as typeof Component;
export default Framere08FtnXfP;

Framere08FtnXfP.displayName = "Evenement dropdown";

Framere08FtnXfP.defaultProps = {height: 27, width: 99.5};

addPropertyControls(Framere08FtnXfP, {variant: {options: ["sRoEZwoUQ", "Ug3_xLLh9"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}, PRG4daZ8L: {defaultValue: false, title: "Subnav", type: ControlType.Boolean}, HGoxJpwjL: {defaultValue: "Evenementen", displayTextArea: false, title: "Title", type: ControlType.String}, xA9wAxaZt: {title: "Link", type: ControlType.Link}, E3x7ifE4G: {title: "Click 3", type: ControlType.EventHandler}, KAQrmVyfg: {title: "Hover 2", type: ControlType.EventHandler}, DKgLhN2qI: {title: "Hover 3", type: ControlType.EventHandler}} as any)

addFonts(Framere08FtnXfP, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...HeroFonts, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})